import React, { useEffect } from 'react';
import { StyledFormItem, StyledFormItemRangePicker, StyledParamsForm } from '../../common/styled';
import { Button, Form } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { formItemMap } from '@med-fe/ui';
import { ReportName } from '../models/report-name.enum';
import { SLOT_COUNT_CONFIG } from './filter-configs/slot-count-config';
import { OD_ASSIGNMENTS_CONFIG } from './filter-configs/od-assignments-config';
import { SPECIAL_ASSIGNMENTS_CONFIG } from './filter-configs/special-assignments-config';
import { CHANGE_PATTERN_CONFIG } from './filter-configs/change-pattern-config';
import { useDispatch, useSelector } from 'react-redux';
import { exportReportFile } from '../reports-actions';
import { getReportsLoading } from '../reports-selector';
import { isNil, omitBy } from 'lodash';
import styled from 'styled-components';
import { AVERAGE_PACE_BY_OD_CONFIG } from './filter-configs/average-pace-by-od-config';
import { HOO_TO_SCHEDULE_CONFIG } from './filter-configs/hoo-to-schedule-config';
import { HOO_TO_RANGE_ZERO_CONFIG } from './filter-configs/hoo-to-range-zero-config';
import moment from 'moment';

export const FiltersForm = ({ reportName }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector((state) => getReportsLoading(state, reportName));
  const filterConfigsMap = {
    [ReportName.SlotCount]: SLOT_COUNT_CONFIG,
    [ReportName.ODAssignments]: OD_ASSIGNMENTS_CONFIG,
    [ReportName.ChangePattern]: CHANGE_PATTERN_CONFIG,
    [ReportName.SpecialAssignments]: SPECIAL_ASSIGNMENTS_CONFIG,
    [ReportName.AveragePaceByOD]: AVERAGE_PACE_BY_OD_CONFIG,
    [ReportName.HOOToScheduleAudit]: HOO_TO_SCHEDULE_CONFIG,
    [ReportName.HOOToRangeZeroAudit]: HOO_TO_RANGE_ZERO_CONFIG,
  };

  const generateFormItem = (props, children, type) => {
    let formItem = <StyledFormItem {...props}>{children}</StyledFormItem>;

    if (type === 'daterange') {
      formItem = <StyledFormItemRangePicker {...props}>{children}</StyledFormItemRangePicker>;
    }

    return formItem;
  };

  const downloadReport = () => {
    const formData = form.getFieldsValue();
    const defaultDates = {
      dateRange: [moment()],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: null,
    };
    const payload = {
      ...formData,
      ...(formData.dateRange
        ? {
            startDate: formData.dateRange[0] ? formData.dateRange[0].format('YYYY-MM-DD') : null,
            endDate: formData.dateRange[1] ? formData.dateRange[1].format('YYYY-MM-DD') : null,
          }
        : ReportName.HOOToScheduleAudit || ReportName.HOOToRangeZeroAudit
        ? defaultDates
        : null),
    };

    dispatch(
      exportReportFile({
        type: 'xlsx',
        fileName: reportName,
        ...omitBy(payload, (val) => isNil(val) || val === ''),
      })
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [reportName]);

  return (
    <StyledParamsForm form={form} layout='vertical' className='search-container'>
      {Object.entries(filterConfigsMap[reportName]).map(([key, item]: [string, any]) =>
        generateFormItem({ key, name: key, label: item.label }, formItemMap(item), item.type)
      )}
      <DownloadButton>
        <Button
          disabled={
            reportName !== ReportName.SlotCount &&
            reportName !== ReportName.ODAssignments &&
            reportName !== ReportName.SpecialAssignments &&
            reportName !== ReportName.AveragePaceByOD &&
            reportName !== ReportName.ChangePattern &&
            reportName !== ReportName.HOOToScheduleAudit &&
            reportName !== ReportName.HOOToRangeZeroAudit
          }
          type={'dashed'}
          size={'large'}
          icon={<DownloadOutlined />}
          loading={loading}
          onClick={() => downloadReport()}
        >
          DOWNLOAD REPORT
        </Button>
      </DownloadButton>
    </StyledParamsForm>
  );
};

const DownloadButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 170px 0 30px;
`;
